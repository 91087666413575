<template>
  <div v-if="isMeinZweitmarkt">
    <p><strong>So erreichen Sie unser Händlerteam:</strong></p>
    <p>
      040 / 480 920 – 30<br/>
      <a href="mailto:info@zweitmarkt.de">info@zweitmarkt.de</a>
    </p>
  </div>

  <div v-else-if="isMeinHandel" class="text-right pb-5">
    <p><strong>Fragen zum Handel?</strong></p>
    <h4>040 / 30 70 26 00</h4>
  </div>
</template>

<script>
export default {
  name: "FdbContact",
  computed: {
    client() {
      return this.$route?.params?.client
    },
    isMeinZweitmarkt() {
      if (this.client === "meinzweitmarkt") {
        return true;
      }
      return false;
    },
    isMeinHandel() {
      if (this.client === "meinhandel") {
        return true;
      }
      return false;
    },
  }
}
</script>

<style scoped>

</style>