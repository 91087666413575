<template>
  <div>
    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText v-if="isMeinHandel" :titletext="'Deutsche Zweitmarkt AG'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
            <HeadText v-else :titletext="'Ihr Vermittlungsstatus'" :subtext="''"></HeadText>
          </div>
          <div class="container bg-light p-3" v-if="!hasToken" >
            <div class="alert alert-warning" role="alert">
              <p>Bitte melden Sie sich an, um auf diese Seite zuzugreifen.</p>
            </div>
          </div>
          <div v-else >
            <div class="container bg-light pt-3 px-3" >                      
              <b-row>
                <b-col md="6" class="text-left">
                  <p>
                    Sobald einer Ihrer Aufträge vermittelt wurde wird diese Vermittlung hier angezeigt. Klicken Sie auf
                    die Vermittlungsnummer und sehen Sie in der Detailansicht, in welchem Stadium sich Ihre Vermittlung
                    zu dem entsprechenden Auftrag aktuell befindet.
                  </p>
                </b-col>
                <b-col md="6" class="text-left">
                  <FdbContact></FdbContact>
                </b-col>
              </b-row>
            </div>
            
            <div class="container bg-light">
              <VermittlungListTableMobile v-if="mobileViewport"></VermittlungListTableMobile> <!-- AuftragListTableMobile -->
              <VermittlungListTable v-else></VermittlungListTable>
            </div>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbContact from "@/components/FdbContact";
import VermittlungListTable from "../components/vermittlung/VermittlungListTable";
import VermittlungListTableMobile from "../components/vermittlung/VermittlungListTableMobile";

export default {
  name: "Auftragsliste",
  components: {Hero, HeadText, FdbContact, VermittlungListTable, VermittlungListTableMobile},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ]
    }
  },
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    }
  },
}
</script>

<style scoped>

</style>