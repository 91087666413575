<template>
  <div 
      class="text-left"
      :class="sortClass"
      v-on:click="$emit( 'sort-clicked', 
      sortpropparam = mySortProp(), sortdirparam = calcNewSort() )"
  >
    <span><i class="fas" :class="caretStyle" aria-hidden="true"></i></span>
    <span class="pl-1"><slot /></span>
  </div>
</template>

<script>

export default {
  name: "TableHeaderSortElement",
  props: {
    sortprop: {
      type: String,
      default: '',
    },
    sortdir: {
      type: String,
      default: 'none',
    },
  },
  computed: {
    sortClass() {
      return {
        'pointer': this.sortprop
        ,      }
    },
    caretStyle() {
      if ( 'asc' === this.sortdir ) {
        return 'fa-sort-up';
      }
      else if ( 'desc' === this.sortdir ) {
        return 'fa-sort-down';
      }
      else {
        return 'fa-sort';
      }
      
    }
  },
  methods: {
    calcNewSort() {
      let newSortDirection;

      if (this.sortdir === 'asc') {
        newSortDirection = 'desc';
      } else {
        newSortDirection = 'asc';
      }

      if (this.sortdir === 'none') {
        newSortDirection = 'asc';
      }

      return newSortDirection;
    },
    mySortProp() {
      return this.sortprop;
    }
  }
}
</script>

<style scoped>

</style>