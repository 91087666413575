<template>
  <div>
    <b-modal id="noteDetails"
             title="Notiz"
             header-bg-variant="dark"
             :hide-footer="isNoteStoring">
      <template #modal-footer="{}">
        <b-button variant="secondary" @click="cancelAddNote" class="btn btn-default btn-form">Abbrechen</b-button>
        <button v-if="hasNote" @click="deleteNote" class="btn btn-warning btn-form">Löschen</button>
        <b-button variant="primary" @click="submitForm" class="btn btn-default btn-form">Speichern</b-button>
      </template>
      <div class="text-center my-2" v-if="isNoteStoring">
        {{this.hasNote.objectType}}
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Daten werden geladen...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasNoteError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <div class="my-4" v-else>
          <b-form id="noteForm">
            <div class="form-row">
              <div class="form-group col-md-12">
                <p v-if="!hasNote">
                  Bitte legen Sie hier Ihre Notiz an.
                </p>
                <p v-if="hasNote">
                  <span class="small">Anlagedatum:</span><br />
                  {{ formatDateTime(this.noteData.lastUpdated) }}
                </p>
                <label class="small">Titel</label>
                <b-form-input
                    type="text"
                    class="form-control form-control-sm"
                    aria-describedby="titleInputField-live-feedback"
                    v-model.trim="$v.form.title.$model"
                    :state="validateState('title')">
                </b-form-input>
                <b-form-invalid-feedback id="titleInputField-live-feedback">
                  <span v-if="!$v.form.title.maxLength">Der Titel darf maximal 255 Zeichen betragen.</span>
                  <span v-else>Bitte geben Sie einen Titel an.</span>
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="small">Inhalt</label>
                <b-form-textarea
                    type="text"
                    class="form-control form-control-sm"
                    aria-describedby="contentInputField-live-feedback"
                    v-model.trim="$v.form.content.$model"
                    :state="validateState('content')">
                </b-form-textarea>
                <b-form-invalid-feedback id="contentInputField-live-feedback">
                  <span v-if="!$v.form.content.maxLength">Der Inhalt darf maximal 4000 Zeichen betragen.</span>
                  <span v-else>Bitte geben Sie einen Inhalt an.</span>
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <p>Bitte beachten Sie: Notizen sind nur für Sie sichtbar.</p>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import dateMixin from "@/mixins/formatDate";
import { mapGetters } from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength,required} from "vuelidate/lib/validators";

export default {
  name: "NoteDetails",
  props: {
    objectId: {
      type: Number,
    },
    objectType: {
      type: String,
    },
    hasNote: {
      type: Boolean,
    },
  },
  data() {
    return {
      noteData: '',
      form: {
        title: '',
        content: '',
      },
      validationResults: [],
    }
  },
  validations() {
    return {
      form: {
        title: {
          required,
          maxLength: maxLength(255),
        },
        content: {
          required,
          maxLength: maxLength(4000)
        }
      }
    }
  },
  computed: {
    ...mapGetters('notes', ['getNoteData','isNoteWaiting','isSuccess']),
    isNoteStoring() {
      return this.$store.getters["notes/isNoteWaiting"];
    },
    hasNoteError() {
      var noteStatus = this.$store.getters["notes/noteStatus"];
      return !(noteStatus === undefined || noteStatus === null || noteStatus === '' || noteStatus === "" )
    }
  },
  methods: {
    resetData() {
      this.$store.dispatch('notes/resetNoteData');  
    },
    getNote() {
      if(!this.hasNote) {
        this.noteData = {
          lastUpdated: null,
          title: '',
          content: '',
        }
      } else if(this.objectId !== null) {
        const reqParams = {
          objectId: this.objectId,
          objectType: this.objectType,
        };
        this.$store.dispatch('notes/loadNote',reqParams).then(response => {
          let debugRun = undefined;
          if ( debugRun ) console.log( response )
          this.noteData= this.$store.getters["notes/getNoteData"];
        }, error => {
          console.log( error )
        })
      }
    },
    submitForm(event) {
      event.preventDefault();
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        this.submitInProgress = false;
        return;
      }
      this.submitNote();
    },
    submitNote(){
      const payload = {
        hasNote: this.hasNote,
        objectId: this.objectId,
        objectType: this.objectType,
        title: this.form.title,
        content: this.form.content,
      }
      this.$store.dispatch('notes/saveNoteData', payload);
      this.setNote();
    },
    deleteNote(){
      const payload = {
        objectId: this.objectId,
        objectType: this.objectType,
      }
      this.$store.dispatch('notes/deleteNoteData', payload);
      this.cancelNote();
    },
    cancelAddNote() {
      this.$bvModal.hide( 'noteDetails' );
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    formatDateTime(n) {
      if(n != null) {
        // Date
        const date = n.split("T")[0]
        const formattedDate = date.split("-")

        // Time
        const timeString = n.split("T")[1]
        const formattedTime = timeString.split(".")[0]
        const shortenedTime = formattedTime.split(":")

        return formattedDate[2] + "." + formattedDate[1] + "." + formattedDate[0] + " - " + shortenedTime[0] + ":" + shortenedTime[1];
      }
    },
    setNote() {
      this.$emit('changeNoteState',true)
    },
    cancelNote() {
      this.$emit('changeNoteState',false)
    },
  },
  beforeCreate() {
    this.resetData;
  },
  mixins: [validationMixin],
  watch: {
    noteData: function() {
      if (this.noteData) {
        this.form.lastUpdated = this.noteData.lastUpdated;
        this.form.title = this.noteData.title;
        this.form.content = this.noteData.content;
      }
    },
    isSuccess: function() {
      if ( !this.isNoteStoring && this.isSuccess ) {
        this.$bvModal.hide( 'noteDetails' );
        this.$emit('successReload', 'yes')
      }
    },
    objectId: function () {
      this.getNote();
    }
  }
}
</script>

<style scoped>

</style>