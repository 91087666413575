export default {
    methods: {
        formatPrice(value) {
            if ( !value ) {
                return "";
            }
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatPriceNoCents(value) {
            if ( !value ) {
                return "";
            }
            let val = (value / 1).toFixed(0).replace('.', '')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    }
}