<template>
  <div>
    
    <div class="row py-3">
      <div class="col-sm-12">
        <div v-if="hasVermittlunglistError()">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ vermittlunglistError() }}</p>
          </div>
        </div>

        <b-form class="pt-3" @submit="onSubmit" inline>
          <b-container fluid>
            <b-row class="my-1">
              <b-col md="4" class="pl-0 pr-mobile-0">
                <div class="form-group mb-sm-3">
                  <input class="form-control w-100"
                         id="vermittlungnr"
                         type="number"
                         v-model="form.vermittlungnr"
                         placeholder="Vermittlungsnummer">
                </div>
              </b-col>
              <b-col md="4" class="pl-0 pr-mobile-0">
                <div class="form-group mb-sm-3">
                  <input class="form-control w-100"
                         id="fonds"
                         type="text"
                         v-model="form.searchcombinedname"
                         placeholder="Fonds">
                </div>
              </b-col>
              <b-col md="2" class="text-left pl-mobile-0 col-xs-6 col-sm-6">
                <div class="form-group">
                  <b-button v-b-toggle:extended-search>
                    Weitere Filter&#160;
                    <span class="when-open"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    <span class="when-closed"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                  </b-button>
                </div>
              </b-col>
              <b-col md="2" class="text-right pr-0 col-xs-6 col-sm-6">
                <div>
                  <b-button
                      variant="primary"
                      class="btn btn-default btn-form mb-2"
                      type="submit">Suchen</b-button>
                </div>
              </b-col>
            </b-row>

            <b-collapse id="extended-search" class="pt-3">
              <b-row>
                <b-col md="6">
                  <div class="form-group w-100">
                    <b-form-select
                        id="vermittlungsstatusselect"
                        size="sm"
                        class="w-100"
                        v-model="form.vermittlungstatus"
                        :options="vermittlungstatusoptions">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Status --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-collapse>

          </b-container>
        </b-form>

        <b-container fluid v-show="anyFilter">
          <b-row class="pt-1 badge-row">
            <b-col md="12" class="text-left">
              <b-badge variant="dark" class="mx-1" v-if="form.vermittlungnr">
                {{form.vermittlungnr}} <a class="badge-cancel ml-2" @click="resetFilter('vermittlungnr')"><i class="fas fa-times"></i></a>
              </b-badge>
              <b-badge variant="dark" class="mx-1" v-if="form.searchcombinedname">
                {{form.searchcombinedname}} <a class="badge-cancel ml-2" @click="resetFilter('searchcombinedname')"><i class="fas fa-times"></i></a>
              </b-badge>
              <b-badge variant="dark" class="mx-1" v-if="form.vermittlungstatus">
                {{mapVermittlungstatus()}} <a class="badge-cancel ml-2" @click="resetFilter('vermittlungstatus')"><i class="fas fa-times"></i></a>
              </b-badge>
            </b-col>
          </b-row>
        </b-container>

        <b-container fluid v-if="!this.isDefaultSort()">
          <b-row class="pt-1">
            <b-col md="12" class="text-left pl-0">
              <b-badge variant="warning" class="mr-1">
                Sortierung nach {{ getSortObject( form.sortproperty ).displayName }}, {{ form.sortdirection === 'asc' ? 'auf' : 'ab' }}steigend
                <a class="badge-cancel ml-2" @click="resetSort()"><i class="fas fa-times"></i></a>
              </b-badge>
            </b-col>
          </b-row>
        </b-container>
        
        <b-table id="vermittlungslistetable" ref="vermittlungslistetable" class="pt-3"
            bordered
            striped
            responsive="xl"
            :isBusy.sync="isBusy"     
            :items="dataProvider"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :key="submitCount"
            head-variant="dark"
        >
          <template #thead-top="">
            <b-tr variant="dark">
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'VERMITTLUNG_NR' ).key"
                    :sortdir="getSortObject( 'VERMITTLUNG_NR' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Nr.
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'VERMITTLUNG_DATUM' ).key"
                    :sortdir="getSortObject( 'VERMITTLUNG_DATUM' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Datum
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none; white-space: nowrap" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'VERMITTLUNG_STATUS' ).key"
                    :sortdir="getSortObject( 'VERMITTLUNG_STATUS' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Status
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none; white-space: nowrap" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'VERMITTLUNG_STATUS_SEIT' ).key"
                    :sortdir="getSortObject( 'VERMITTLUNG_STATUS_SEIT' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  seit
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-25-percent text-left">
                <table-header-sort-element
                    :sortprop="getSortObject( 'FONDSNAME' ).key"
                    :sortdir="getSortObject( 'FONDSNAME' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Fonds
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-15-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'NOMINALE' ).key"
                    :sortdir="getSortObject( 'NOMINALE' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  <span style="overflow-wrap: break-word;">Nominale</span>
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'KURS' ).key"
                    :sortdir="getSortObject( 'KURS' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  <span style="overflow-wrap: break-word;">Kurs</span>
                </table-header-sort-element>
              </b-th>
              <b-th class="col-5-percent" style="border-bottom: none">
                <!-- Actions -->
              </b-th>
            </b-tr>
          </template>

          <template #cell(vermittlungsnr)="data">
            <div class="text-left">
              <router-link :to="{name: 'vermittlungsdetails', params: {client: client, id: data.item.id}}">{{ data.item.vermittlungNr }}</router-link>
            </div>
          </template>

          <template #cell(vermittlungsdatum)="data">
            <div class="text-left">
              {{ reformatDate( data.item.vermittlungsdatum ) }}
            </div>
          </template>

          <template #cell(vermittlungsstatus)="data">
            <div class="text-left">
              {{ data.item.vermittlungsstatus }}
            </div>
          </template>

          <template #cell(vermittlungsstatusSeit)="data">
            <div class="text-left">
              {{ reformatDate( data.item.vermittlungsstatusSeit ) }}
            </div>
          </template>

          <template #cell(fonds)="data">
            <div class="text-left">
              <router-link :to="{name: 'fondsdetails', params: {client: client, id: data.item.trancheId}}">
                {{ data.item.anbietername }} - {{ fondsNameWithZusatz( data.item ) }}
              </router-link>
            </div>
          </template>

          <template #cell(nominale)="data">
            <div class="text-left" style="white-space: nowrap">
              {{ formatPrice( data.item.nominale.betrag ) }} {{ data.item.nominale.waehrung }}
            </div>
          </template>

          <template #cell(kurs)="data">
            <div class="text-left">
              {{ formatPercent( data.item.kurs ) }}%
            </div>
          </template>

          <template #cell(actions)="data">
            <div v-if="!data.item.canCreateNote"></div>
            <div v-else-if="data.item.hasNote">
              <a @click="addNote(data.item.id, data.item.hasNote)" class="pt-2 pl-1 watchLink">
                <i class="fas fa-clipboard" title="Notiz bearbeiten"></i>
              </a>
            </div>
            <div v-else>
              <a @click="addNote(data.item.id, data.item.hasNote)" class="pt-2 pl-1 watchLink">
                <i class="fas fa-notes-medical" title="Notiz erstellen"></i>
              </a>
            </div>
          </template>
          
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>

        <b-pagination
            id="pagination"
            v-model="currentPage"
            :total-rows="vermittlunglistCount"
            :per-page="perPage"
            aria-controls="vermittlungslistetable"
            align="right"
            v-if="vermittlunglistCount > perPage"
        ></b-pagination>

        <NoteDetails v-bind:objectId="objectId" v-bind:objectType="'VERMITTLUNG'" @successReload="forceRerender" v-bind:hasNote="hasNote" v-show="false"></NoteDetails>
        
      </div>
    </div>
    
  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import dateMixin from "@/mixins/formatDate";
import TableHeaderSortElement from "@/components/sorting/TableHeaderSortElement";
import NoteDetails from "@/components/notes/NoteDetails"
import axios from "axios";

export default {
  name: "VermittlungListTable",
  components: {TableHeaderSortElement,NoteDetails},
  data: () => ({
    /* table controls */
    currentPage: 1,
    perPage: 25,
    submitCount: 0,
    vermittlunglistCount: 0,
    isBusy: false,
    error: null,
    
    objectId: null,
    objectType: '',
    hasNote: false,
    form: {
      vermittlungnr: '',
      searchcombinedname: '',
      vermittlungstatus: null,
      sortproperty: 'VERMITTLUNG_DATUM',
      sortdirection: 'desc',
    },
    vermittlungstatusoptions: [
      { value: 'ALLE', text: 'Alle' },
      { value: 'ERSTELLT', text: 'Erstellt' },
      { value: 'BEIM_1_KUNDEN', text: 'Beim 1. Kunden' },
      { value: 'VOM_1_KUNDEN_ZURUECK', text: 'Vom 1. Kunden zurück' },
      { value: 'BEIM_2_KUNDEN', text: 'Beim 2. Kunden' },
      { value: 'KUUE_LIEGT_VOR', text: 'KuÜ liegt vor' },
      { value: 'FERTIG_FUER_TH', text: 'Fertig für den Treuhänder' },
      { value: 'BEIM_TH', text: 'Beim Treuhänder' },
      { value: 'MANUELL', text: 'Manuell' },
      { value: 'KLAEREN', text: 'Klären' },
      { value: 'FREIGEGEBEN', text: 'Freigegeben' },
      { value: 'IN_ABRECHNUNG', text: 'In Abrechnung' },
      { value: 'ABGESCHLOSSEN', text: 'Abgeschlossen' },
    ],
    sortoptions: [
      { key: 'VERMITTLUNG_NR', name: 'VERMITTLUNG_NR', sortdir: 'none', displayName: 'Vermittlung' },
      { key: 'VERMITTLUNG_DATUM', name: 'VERMITTLUNG_DATUM', sortdir: 'desc', displayName: 'Datum' },
      { key: 'VERMITTLUNG_STATUS', name: 'VERMITTLUNG_STATUS', sortdir: 'none', displayName: 'Status' },
      { key: 'VERMITTLUNG_STATUS_SEIT', name: 'VERMITTLUNG_STATUS_SEIT', sortdir: 'none', displayName: 'Status seit' },
      { key: 'NOMINALE', name: 'NOMINALE', sortdir: 'none', displayName: 'Nominale' },
      { key: 'KURS', name: 'KURS', sortdir: 'none', displayName: 'Kurs' },
      { key: 'FONDSNAME', name: 'FONDSNAME', sortdir: 'none', displayName: 'Fonds' },
    ],
  }),
  computed: {
    fields() {
      return [
        { key: 'vermittlungsnr', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'vermittlungsdatum', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'vermittlungsstatus', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'vermittlungsstatusSeit', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'fonds', label: '', thClass: ["text-left", "hidden-header", "col-20-percent"], tdClass: ["text-left", "col-20-percent"] },
        { key: 'nominale', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'kurs', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'actions', label: '', thClass: ["text-left", "hidden-header", "col-5-percent"], tdClass: ["col-5-percent", "text-center"] },
      ] 
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
  },
  methods: {
    isDefaultSort() {
      return this.form.sortproperty === 'VERMITTLUNG_DATUM' && this.form.sortdirection === 'desc';
    },
    resetSort() {
      this.form.sortproperty = 'VERMITTLUNG_DATUM';
      this.form.sortdirection = 'desc';
      this.onSort( this.form.sortproperty, this.form.sortdirection );
    },
    resetFilter( fieldKey ) {
      if ( 'searchcombinedname' === fieldKey ) {
        this.form.searchcombinedname = null;
      }
      else if ( 'vermittlungnr' === fieldKey ) {
        this.form.vermittlungnr = null;
      }
      else if ( 'vermittlungstatus' === fieldKey ) {
        this.form.vermittlungstatus = null;
      }
      else {
        console.log( 'Unknown form field key ' + fieldKey );
      }
      let event = null;
      this.onSubmit( event );
    },
    anyFilter() {
      return this.form.searchcombinedname ||
          this.form.vermittlungstatus ||
          this.form.vermittlungnr;
    },
    mapVermittlungstatus() {
      if (!this.form.vermittlungstatus) return '';
      for( var opt of this.vermittlungstatusoptions ) {
        if (opt.value === this.form.vermittlungstatus ) {
          let res = opt.text;
          if ( res.includes('- ') ) {
            res = res.substring( 2 );
          }
          if ( this.form.vermittlungstatus === 'ALLE' ) {
            return 'Vermittlungstatus: ' + res;
          }
          return res;
        }
      }
      return '';
    },
    hasVermittlunglistError() {
      var status = this.vermittlunglistError();
      return !(status === undefined || status === null || status === '' || status === "" )
    },
    vermittlunglistError() {
      return this.error;
    },
    forceRerender() {
      this.submitCount++;  
    },
    redirectToVermittlung() {
      const reqParams = {
        client: this.$route?.params?.client,
        vermittlungNr: this.form.vermittlungnr
      }
      this.$store.dispatch('vermittlungsliste/redirectToVermittlung',reqParams);
    },
    dataProvider( ctx, callback ) {
      this.isBusy = true;
      this.error = null;
      const reqParams = {
        fondsname:          this.form.searchcombinedname,
        vermittlungStatus:  this.form.vermittlungstatus? this.form.vermittlungstatus : 'ALLE',
        page:               ctx.currentPage - 1,
        size:               ctx.perPage,
        sort:               this.form.sortproperty ? this.form.sortproperty : '',
        sortDir:            this.form.sortproperty ? this.form.sortdirection.toUpperCase() : ''
      };
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        crossdomain: true,
        params: reqParams,
      };

      axios.get(this.$store.getters.getAllEnvis.API_USER_VERMITTLUNGEN, config)
          .then(response => {
            const items = response.data.content;
            callback( items );
            this.vermittlunglistCount = response.data.page.total_elements;
            this.isBusy = false;
          }, error => {
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.isBusy = false;
            this.error = error;
            callback( [] );
          })
      
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      if ( this.form.vermittlungnr && this.form.vermittlungnr !== '' ) {
        this.redirectToVermittlung();
      }
      else {
        this.currentPage = 1;
        this.forceRerender();
      }
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }  
      return [];
    },
    onSort: function( sortpropkey, sortdir ) {
      var sortopt = this.getSortObject( sortpropkey );
      var newSortDir = sortdir !== 'none' ? sortdir : 'asc'
      this.form.sortproperty = sortopt.name;
      this.form.sortdirection = newSortDir;
      for( var s of this.sortoptions ) {
        if( s.key === sortpropkey ) {
          s.sortdir = newSortDir;
        }
        else {
          s.sortdir = 'none';
        }
      }
      let event = null;
      this.onSubmit( event );
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    addNote(id, hasNote) {
      this.objectId = id;
      this.hasNote = hasNote;
      this.noteData = this.$store.getters["notes/getNoteData"];
      this.$bvModal.show( 'noteDetails' );
    }
  },
  mixins: [priceMixin,percentMixin,dateMixin],
  mounted() {
    this.$nextTick(function () {
      if ( !this.isBusy ) {
        this.submitCount++;
      }
    });
  },
  
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style type="scss" scoped>
</style>