export default {
    methods: {
        showDate() {
            let d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('.');
        },
        /*
         * Assume local date format 2021-02-14
         */
        reformatDate(value) {
            if ( !value ) {
                return "";
            }
            else if ( !value.includes('-') ) {
                return value;
            }
            let year = value.substring(0,4);
            let month = value.substring(5,7);
            let day = value.substring(8,10);
            return day + '.' + month + '.' + year;
        }
    }
}



