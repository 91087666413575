<template>
  <div>

    <b-form class="pt-3" @submit="onSubmit" inline>
      <b-container fluid id="mobileSearch">
        <b-row class="bg-white">
          <b-col class="pl-0 p-2">
            <div class="form-group">
              <input class="form-control w-100"
                     id="vermittlungnr"
                     type="number"
                     v-model="form.vermittlungnr"
                     placeholder="Vermittlungsnummer">
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-2">

          </b-col>
        </b-row>
        <b-row class="bg-white">
          <b-col class="pl-0 p-2">
            <div class="form-group">
              <input class="form-control w-100"
                     id="fonds"
                     type="text"
                     v-model="form.searchcombinedname"
                     placeholder="Fonds">
            </div>
          </b-col>
          <b-col class="text-right p-2 col-4">
            <div>
              <b-button
                  variant="primary"
                  class="btn btn-default btn-form"
                  type="submit">Suchen</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-0">
            <b-container fluid v-show="anyFilter">
              <b-row class="pt-1 badge-row">
                <b-col md="12" class="text-left">
                  <b-badge variant="dark" class="mx-1" v-if="form.vermittlungnr">
                    {{form.vermittlungnr}} <a class="badge-cancel ml-2" @click="resetFilter('vermittlungnr')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.searchcombinedname">
                    {{form.searchcombinedname}} <a class="badge-cancel ml-2" @click="resetFilter('searchcombinedname')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.vermittlungstatus">
                    {{mapVermittlungstatus()}} <a class="badge-cancel ml-2" @click="resetFilter('vermittlungstatus')"><i class="fas fa-times"></i></a>
                  </b-badge>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <div class="row py-3">
      <div class="col-sm-12">
        <div v-if="hasVermittlunglistError()">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ vermittlunglistError() }}</p>
          </div>
        </div>

        <b-col class="text-left pl-0 pr-0">

          <b-collapse v-model="sortFilterBar">
            <b-button class="sort-tile mb-3" @click="toggleSortLayer">
              <i class="fa fa-sort-alt"></i> Sortieren<br />
              <span class="currentSort">
                {{ getSortObject( form.sortproperty ).displayName }}
                <i class="fas fa-long-arrow-alt-down" v-if="currentSortDirIsDesc()"></i>
                <i class="fas fa-long-arrow-alt-up" v-else></i>
                <!-- a class="ml-2" @click="resetSort()"><i class="fas fa-times"></i></a -->
              </span>
            </b-button>

            <b-button class="sort-tile mb-3" @click="toggleFilterLayer">
              <i class="fa fa-filter"></i> Filter<br />
              <span class="currentSort">({{ vermittlunglistCount }} Ergebnisse)</span>
            </b-button>
          </b-collapse>

          <b-collapse v-model="filterToggle" id="extended-search" class="pt-3 pl-0 pr-0">
            <b-row class="ml-0 mr-0">
              <b-col md="3" class="mb-2 pl-0">
                <div class="form-group w-100">
                  <b-form-select
                      id="vermittlungsstatusselect"
                      size="sm"
                      class="w-100"
                      v-model="form.vermittlungstatus"
                      :options="vermittlungstatusoptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Status --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
            </b-row>
          </b-collapse>

        </b-col>

        <b-collapse id="mobile-sort" class="pt-3" v-model="sortToggle">
          <b-row>
            <b-col class="text-left">
              <ul>
                <li v-on:click="onSort('VERMITTLUNG_NR');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Nr.
                </li>
                <li v-on:click="onSort('VERMITTLUNG_DATUM');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Datum
                </li>
                <li v-on:click="onSort('VERMITTLUNG_STATUS');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Status
                </li>
                <li v-on:click="onSort('VERMITTLUNG_STATUS_SEIT');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> seit
                </li>
                <li v-on:click="onSort('FONDSNAME');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Fonds
                </li>
                <li v-on:click="onSort('NOMINALE');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Nominale
                </li>
                <li v-on:click="onSort('KURS');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Kurs
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-collapse>

        <b-table id="vermittlungslistetable" ref="vermittlungslistetable" class="mobileTable pt-3"
            bordered
            stacked
            responsive="xl"
            :isBusy.sync="isBusy"
            :items="dataProvider"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :key="submitCount"
            head-variant="dark"
        >

          <template #cell(vermittlungsnr)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  <strong>
                    Nr.
                  </strong>
                </td>
                <td class="label2">
                  <strong>
                    <router-link :to="{name: 'vermittlungsdetails', params: {client: client, id: data.item.id}}">{{ data.item.vermittlungNr }}</router-link>
                  </strong>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(vermittlungsdatum)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Datum
                </td>
                <td class="label2">
                  {{ reformatDate( data.item.vermittlungsdatum ) }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(vermittlungsstatus)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Status
                </td>
                <td class="label2">
                  {{ data.item.vermittlungsstatus }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(vermittlungsstatusSeit)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  seit
                </td>
                <td class="label2">
                  {{ reformatDate( data.item.vermittlungsstatusSeit ) }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(fonds)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Fonds
                </td>
                <td class="label2">
                  <router-link :to="{name: 'fondsdetails', params: {client: client, id: data.item.trancheId}}">
                    {{ data.item.anbietername }} - {{ fondsNameWithZusatz( data.item ) }}
                  </router-link>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(nominale)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Nominale
                </td>
                <td class="label2">
                  {{ formatPrice( data.item.nominale.betrag ) }} {{ data.item.nominale.waehrung }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(kurs)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Kurs
                </td>
                <td class="label2">
                  {{ formatPercent( data.item.kurs ) }}%
                </td>
              </tr>
            </table>
          </template>

          <template #cell(actions)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  &nbsp;
                </td>
                <td class="label2">
                  <div v-if="!data.item.canCreateNote"></div>
                  <div v-else-if="data.item.hasNote">
                    <a @click="addNote(data.item.id, data.item.hasNote)" class="pt-2 pl-1 watchLink">
                      <i class="fas fa-clipboard" title="Notiz bearbeiten"></i>
                    </a>
                  </div>
                  <div v-else>
                    <a @click="addNote(data.item.id, data.item.hasNote)" class="pt-2 pl-1 watchLink">
                      <i class="fas fa-notes-medical" title="Notiz erstellen"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(empty)="">
            <table class="innerTable">
              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>

        <b-pagination
            id="pagination"
            v-model="currentPage"
            :total-rows="vermittlunglistCount"
            :per-page="perPage"
            aria-controls="vermittlungslistetable"
            align="right"
            v-if="vermittlunglistCount > perPage"
        ></b-pagination>

        <NoteDetails v-bind:objectId="objectId" v-bind:objectType="'VERMITTLUNG'" @successReload="forceRerender" v-bind:hasNote="hasNote" v-show="false"></NoteDetails>

      </div>
    </div>

  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import dateMixin from "@/mixins/formatDate";
import NoteDetails from "@/components/notes/NoteDetails"
import axios from "axios";

export default {
  name: "VermittlungListTable",
  components: {NoteDetails},
  data: () => ({
    /* table controls */
    currentPage: 1,
    perPage: 25,
    submitCount: 0,
    vermittlunglistCount: 0,
    isBusy: false,
    error: null,
    
    objectId: null,
    objectType: '',
    hasNote: false,
    form: {
      vermittlungnr: '',
      searchcombinedname: '',
      vermittlungstatus: null,
      sortproperty: 'VERMITTLUNG_DATUM',
      sortdirection: 'desc',
    },
    sortFilterBar: true,
    sortToggle: false,
    filterToggle: false,
    vermittlungstatusoptions: [
      { value: 'ALLE', text: 'Alle' },
      { value: 'ERSTELLT', text: 'Erstellt' },
      { value: 'BEIM_1_KUNDEN', text: 'Beim 1. Kunden' },
      { value: 'VOM_1_KUNDEN_ZURUECK', text: 'Vom 1. Kunden zurück' },
      { value: 'BEIM_2_KUNDEN', text: 'Beim 2. Kunden' },
      { value: 'KUUE_LIEGT_VOR', text: 'KuÜ liegt vor' },
      { value: 'FERTIG_FUER_TH', text: 'Fertig für den Treuhänder' },
      { value: 'BEIM_TH', text: 'Beim Treuhänder' },
      { value: 'MANUELL', text: 'Manuell' },
      { value: 'KLAEREN', text: 'Klären' },
      { value: 'FREIGEGEBEN', text: 'Freigegeben' },
      { value: 'IN_ABRECHNUNG', text: 'In Abrechnung' },
      { value: 'ABGESCHLOSSEN', text: 'Abgeschlossen' },
    ],
    sortoptions: [
      { key: 'VERMITTLUNG_NR', name: 'VERMITTLUNG_NR', sortdir: 'none', displayName: 'Vermittlung' },
      { key: 'VERMITTLUNG_DATUM', name: 'VERMITTLUNG_DATUM', sortdir: 'desc', displayName: 'Datum' },
      { key: 'VERMITTLUNG_STATUS', name: 'VERMITTLUNG_STATUS', sortdir: 'none', displayName: 'Status' },
      { key: 'VERMITTLUNG_STATUS_SEIT', name: 'VERMITTLUNG_STATUS_SEIT', sortdir: 'none', displayName: 'Status seit' },
      { key: 'NOMINALE', name: 'NOMINALE', sortdir: 'none', displayName: 'Nominale' },
      { key: 'KURS', name: 'KURS', sortdir: 'none', displayName: 'Kurs' },
      { key: 'FONDSNAME', name: 'FONDSNAME', sortdir: 'none', displayName: 'Fonds' },
    ],
  }),
  computed: {
    fields() {
      return [
        { key: 'vermittlungsnr', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'vermittlungsdatum', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'vermittlungsstatus', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'vermittlungsstatusSeit', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'fonds', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'nominale', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'kurs', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'actions', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'empty', label: '', tdClass: ["mobileRow"] }
      ] 
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
  },
  methods: {
    isDefaultSort() {
      return this.form.sortproperty === 'VERMITTLUNG_DATUM' && this.form.sortdirection === 'desc';
    },
    resetSort() {
      this.form.sortproperty = 'VERMITTLUNG_DATUM';
      this.form.sortdirection = 'desc';
      this.onSort( this.form.sortproperty, this.form.sortdirection );
    },
    resetFilter( fieldKey ) {
      if ( 'searchcombinedname' === fieldKey ) {
        this.form.searchcombinedname = null;
      }
      else if ( 'vermittlungnr' === fieldKey ) {
        this.form.vermittlungnr = null;
      }
      else if ( 'vermittlungstatus' === fieldKey ) {
        this.form.vermittlungstatus = null;
      }
      else {
        console.log( 'Unknown form field key ' + fieldKey );
      }
      let event = null;
      this.onSubmit( event );
    },
    anyFilter() {
      return this.form.searchcombinedname ||
          this.form.vermittlungstatus ||
          this.form.vermittlungnr;
    },
    currentSortDirIsDesc() {
      var currentSortDir = 'desc';
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          currentSortDir = sortopt.sortdir;
        }
      }
      return currentSortDir === 'desc';
    },
    mapVermittlungstatus() {
      if (!this.form.vermittlungstatus) return '';
      for( var opt of this.vermittlungstatusoptions ) {
        if (opt.value === this.form.vermittlungstatus ) {
          let res = opt.text;
          if ( res.includes('- ') ) {
            res = res.substring( 2 );
          }
          if ( this.form.vermittlungstatus === 'ALLE' ) {
            return 'Vermittlungstatus: ' + res;
          }
          return res;
        }
      }
      return '';
    },
    hasVermittlunglistError() {
      var status = this.vermittlunglistError();
      return !(status === undefined || status === null || status === '' || status === "" )
    },
    vermittlunglistError() {
      return this.error;
    },
    forceRerender() {
      this.submitCount++;  
    },
    redirectToVermittlung() {
      const reqParams = {
        client: this.$route?.params?.client,
        vermittlungNr: this.form.vermittlungnr
      }
      this.$store.dispatch('vermittlungsliste/redirectToVermittlung',reqParams);
    },
    dataProvider( ctx, callback ) {
      this.isBusy = true;
      this.error = null;
      const reqParams = {
        fondsname:          this.form.searchcombinedname,
        vermittlungStatus:  this.form.vermittlungstatus? this.form.vermittlungstatus : 'ALLE',
        page:               ctx.currentPage - 1,
        size:               ctx.perPage,
        sort:               this.form.sortproperty ? this.form.sortproperty : '',
        sortDir:            this.form.sortproperty ? this.form.sortdirection.toUpperCase() : ''
      };
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        crossdomain: true,
        params: reqParams,
      };

      axios.get(this.$store.getters.getAllEnvis.API_USER_VERMITTLUNGEN, config)
          .then(response => {
            const items = response.data.content;
            callback( items );
            this.vermittlunglistCount = response.data.page.total_elements;
            this.isBusy = false;
          }, error => {
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.isBusy = false;
            this.error = error;
            callback( [] );
          })
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      if ( this.form.vermittlungnr && this.form.vermittlungnr !== '' ) {
        this.redirectToVermittlung();
      }
      else {
        this.currentPage = 1;
        this.forceRerender();
      }
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }
      return [];
    },
    onSort: function( sortpropkey ) {
      var sortopt = this.getSortObject( sortpropkey );
      // Get current sortdir and then toggle
      if ( 'none' === sortopt.sortdir || 'desc' === sortopt.sortdir ) {
        sortopt.sortdir = 'asc';
      }
      else if ( 'asc' === sortopt.sortdir ) {
        sortopt.sortdir = 'desc';
      }

      var newSort = sortopt.sortdir;

      // reset all others
      for( var sortopt2 of this.sortoptions ) {
        if( sortopt2.key === sortpropkey ) {
          // do nothing;
        }
        else {
          sortopt2.sortdir = 'none';
        }
      }

      this.form.sortproperty = sortpropkey;
      this.form.sortdirection = newSort;

      let event = null;
      this.onSubmit( event );
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    addNote(id, hasNote) {
      this.objectId = id;
      this.hasNote = hasNote;
      this.noteData = this.$store.getters["notes/getNoteData"];
      this.$bvModal.show( 'noteDetails' );
    },
    toggleSortFilterBar() {
      if(!this.sortFilterBar) {
        this.sortFilterBar = true;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }
    },
    toggleSortLayer() {
      if(this.sortToggle) {
        this.sortToggle = false;
        return;
      }

      if(this.filterToggle) {
        this.filterToggle = false;
      }

      if(this.sortFilterBar) {
        this.sortFilterBar = false;
      }

      this.sortToggle = true;
    },
    toggleFilterLayer() {
      if(this.filterToggle) {
        this.filterToggle = false;
        return;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }

      this.filterToggle = true;
    }
  },
  mixins: [priceMixin,percentMixin,dateMixin],
  mounted() {
    this.$nextTick(function () {
      if ( !this.isBusy ) {
        this.submitCount++;
      }
    });
  },
  
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style type="scss" scoped>
</style>